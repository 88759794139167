.form {
    max-width: 800px;
    margin: 0 auto;
  }
  
  
  textarea,
  select,
  input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .label,
  section > label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: white;
    font-size: 14px;
    font-weight: 200;
  }
  
  input[type="submit"],
  .button {
    position: relative;
    background: linear-gradient(176deg, rgba(209,170,41,1) 0%, rgba(234,190,42,1) 45%, rgba(255,226,129,1) 100%);
    color: white;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    margin-top: 20px;
    padding: 20px;
    font-size: 16px;
    letter-spacing: 2px;
    display: block;
    appearance: none;
    border-radius: 4px;
    width: 100%;
    font-weight: 400;
    letter-spacing: 0.5rem;
    transition: 0.3s all;
  }



  button[type="button"] {
    padding: 5px;
    background: #516391;
    color: white;
    letter-spacing: 0px;
    text-transform: none;
    padding: 10px;
    letter-spacing: 1px;
  }
  
  input[type="submit"]:hover,
  button[type="button"]:hover {
    background: #c1d90a;
    color: white;
  }
  
  input[type="submit"]:active {
    transition: 0.3s all;
    top: 2px;
  }
  